<div class="pagination" ng-if="pages.length >= 2">
    <ul class="pagination">
        <li>
            <a ng-click="selectPage(1)">&laquo;</a>
        </li>

        <li>
            <a ng-click="selectPage(currentPage-1)">&lsaquo;</a>
        </li>

        <li ng-repeat="page in pages" ng-class="{active: page==currentPage}"><a ng-click="selectPage(page)">{{page}}</a></li>

        <li>
            <a ng-click="selectPage(currentPage+1)">&rsaquo;</a>
        </li>
        <li>
            <a href="" ng-click="selectPage(numPages)">&raquo;</a>
        </li>
    </ul>
</div>