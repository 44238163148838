<div class="input-group quick-search">
    <span class=input-group-addon>
        <i class="fa fa-group"></i>
    </span>

    <input type="text" ng-model="model"
           ng-change="onChange()"
           uib-typeahead="item as item.{{itemName}} for item in getItemsDecorator($viewValue)"
           typeahead-on-select="onSelectDecorator($item, $model, $label)"
           typeahead-template-url="{{templateUrl}}"
           class="form-control"/>
                        <span class="input-group-btn"> <button
                                class="btn btn-default"
                                type="button"
                                ng-click="onButtonClickDecorator()"><i
                                ng-hide="loading"
                                class="fa fa-list"></i> <i
                                ng-show="loading"
                                class="fa fa-refresh animation-spin"></i></button></span>
    <span class="clear-button" data-ng-click="clearSearchDecorator()"><i class="fa fa-times"></i></span>
</div>
