<ifr-page-title pageTitle="'LEFT_PANEL_ACCESSRIGHTSMANAGEMENT' | translate " />

<section data-widget-grid id="access-permissions-view">
  <react-access-permissions
    current-state-url="vm.$state.current.url"
    will-leave="vm.willLeave"
    on-will-leave-result="onWillLeaveResult"
    is-add-access-permission-selected="vm.isAddAccessPermissionSelected"
  />
</section>
