<ifr-page-title pageTitle="'LEFT_PANEL_NOTIFICATIONS' | translate "/>
<div
  class="jarviswidget jarviswidget-color-darken my-information"
  id="wid-plan-content-0"
>
  <plan-notifications
    type="6040"
    api-url="'/WebPlanningAPI'"
    auth-token="authToken"
    locale="locale"
    theme="theme"
    licenses="licenses"
    on-auth-error="onAuthError"
  ></plan-notifications>
</div>
