<ifr-page-title pageTitle="'LANG_LOGIN_PAGE_TITLE' | translate " />

<page-header is-login-page="true" />

<div id="main" role="main" class="margin-top-10">
  <div class="unauthorized-container">
    <!-- row -->
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="row">
          <div class="col-sm-12">
            <div class="text-center">
              <h1 ng-if="oidcLoginUrl">
                {{'LANG_OIDC_TITLE_REDIRECT' | translate}}
              </h1>
              <h1 ng-if="!oidcLoginUrl">
                {{'LANG_OIDC_TITLE_URL_NOT_FOUND' | translate}}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
  </div>
</div>
