<header id="header">
    <div id="logo-group" class="logo-group">
    <span id="hide-menu" class="btn-header">
        <span> <a toggle-menu iflx-permission-hide="visitorSelfPreRegistration" iflx-toggle-menu-notifier title="{{'LEFT_PANEL_COLLAPSE_PAGE_NAVIGATION' | translate}}"><i
                class="fa fa-reorder"></i></a> </span>
    </span>
        <!-- PLACE YOUR LOGO HERE -->
        <span id="logo" iflx-redirect-home> <img src="styles/img/eVAYO-img/interflex.svg" alt="Interflex"> </span>
        <!-- END LOGO PLACEHOLDER -->
    </div>

    <!--<div class="breadcrumb-header hidden-mobile">-->
    <!-- breadcrumb -->
    <!--<breadcrumb>-->
    <!-- This is auto generated -->
    <!--</breadcrumb>-->
    <!-- end breadcrumb -->
    <!--</div>-->
    <!-- projects dropdown -->
    <div class="project-context hidden-xs">

        <span class="label"><span data-localize="Projects">Projects</span>:</span>
    <span class="project-selector" class="popover-trigger-element dropdown-toggle" data-toggle="dropdown"><span
            data-localize="Recent projects">Recent projects </span> <i class="fa fa-angle-down"></i></span>

        <!-- Suggestion: populate this list with fetch and push technique -->
        <ul class="dropdown-menu">
            <li>
                <a href="javascript:void(0);">Online e-merchant management system - attaching integration with the
                    iOS</a>
            </li>
            <li>
                <a href="javascript:void(0);">Notes on pipeline upgradee</a>
            </li>
            <li>
                <a href="javascript:void(0);">Assesment Report for merchant account</a>
            </li>
            <li class="divider"></li>
            <li>
                <a href="javascript:void(0);"><i class="fa fa-power-off"></i> Clear</a>
            </li>
        </ul>
        <!-- end dropdown-menu-->

    </div>
    <!-- end projects dropdown -->

    <!-- pulled right: nav area -->
    <div class="pull-right">

        <!-- collapse menu button -->

        <!-- end collapse menu -->

        <!-- Top menu profile link : this shows only when top menu is active -->
        <ul id="mobile-profile-img" class="header-dropdown-list hidden-xs padding-5">
            <li class="">
                <a href="#" class="dropdown-toggle no-margin userdropdown" data-toggle="dropdown">
                    <img src="" alt="{{user.name}}" class="online"/>
                </a>
                <ul class="dropdown-menu pull-right">
                    <li>
                        <a href="javascript:void(0);" class="padding-10 padding-top-0 padding-bottom-0"><i
                                class="fa fa-cog"></i> Setting</a>
                    </li>
                    <li class="divider"></li>
                    <li>
                        <a href="#/misc/other/profile" class="padding-10 padding-top-0 padding-bottom-0"> <i
                                class="fa fa-user"></i> <u>P</u>rofile</a>
                    </li>
                    <li class="divider"></li>
                    <li>
                        <a href="javascript:void(0);" class="padding-10 padding-top-0 padding-bottom-0"
                           data-action="toggleShortcut"><i class="fa fa-arrow-down"></i> <u>S</u>hortcut</a>
                    </li>
                    <li class="divider"></li>
                    <li>
                        <a href="javascript:void(0);" class="padding-10 padding-top-0 padding-bottom-0"
                           data-action="launchFullscreen"><i class="fa fa-arrows-alt"></i> Full <u>S</u>creen</a>
                    </li>
                    <li class="divider"></li>
                    <li>
                        <a href="login.html" class="padding-10 padding-top-5 padding-bottom-5" data-action="userLogout"><i
                                class="fa fa-sign-out fa-lg"></i> <strong><u>L</u>ogout</strong></a>
                    </li>
                </ul>
            </li>
        </ul>

        <!-- logout button -->
        <!--<div id="logout" class="btn-header transparent pull-right hidden-mobile hidden-tablet">-->
        <!--<span> <a title="Sign Out" logout-click=""><i class="iflx-font iflx-font-menu iflx-font-sl logout logout-header"></i></a> </span>-->
        <!--</div>-->
        <!-- end logout button -->

        <!-- fullscreen button -->
        <div ng-hide id="fullscreen" class="btn-header transparent pull-right">
        <span> <a full-screen title="Full Screen"><i
                class="fa fa-arrows-alt"></i></a></span>
        </div>
        <!-- end fullscreen button -->


    </div>
</header>

