<ifr-page-title pageTitle="'LEFT_PANEL_DEPT_CALENDAR' | translate " />
<div id="wid-plan-content-0">
  <group-calendar
    api-url="'/WebPlanningAPI'"
    auth-token="authToken"
    locale="locale"
    theme="theme"
    on-auth-error="onAuthError"
    personnelNumberEnabled="personnelNumberEnabled"
  ></group-calendar>
</div>
