<ifr-page-title pageTitle="'UNAUTHORIZED_PAGE_TITLE' | translate "/>
<div class="unauthorized-container">
    <!-- row -->
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="row">
                <div class="col-sm-12">
                    <div class="text-center error-box">
                        <h1 class="error-text-2"> {{'UNAUTHORIZED_ERROR_CODE' | translate}} <span
                                class="particle particle--c"></span><span class="particle particle--a"></span><span
                                class="particle particle--b"></span></h1>

                        <h2 class="font-xl"><strong><i class="fa fa-fw fa-warning fa-lg text-warning"></i>
                            {{'UNAUTHORIZED_HEADER' | translate}}</strong></h2>
                        <br>

                        <p class="lead">
                            {{message}} <br/>
                            <span ng-if="hasDashboardPermission">
                                {{'UNAUTHORIZED_GO_BACK' | translate}}
                            </span>
                        </p>

                        <div class="row" ng-if="hasDashboardPermission">
                            <div class="col-sm-12">
                                <ul class="list-inline">
                                    <li>
                                        <a class="lead cursor-pointer" iflx-redirect-home>{{'UNAUTHORIZED_GO_HOME'| translate}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- end row -->
    </div>
</div>
