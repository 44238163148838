<ifr-page-title pageTitle="'LEFT_PANEL_VISITOR_PREREGISTRATION' | translate " />
<visitor-management-and-person-records
  class="visitor-management"
  person-action="ctrl.$state.params.personAction"
  person-guid="ctrl.$state.params.personGuid"
  current-state-url="ctrl.$state.current.url"
  angular-router-helpers="{
  goToVisitList: ctrl.closeVisitDialogue,
  goToVisitDetail: ctrl.navigateToVisitDetail,
  goToCreateVisitFromTemplete: ctrl.navigateToCreateVisitFromTemplateState,
  goToNewVisit: ctrl.navigateToCreateVisit,
}"
  visit-guid="ctrl.$state.params.visitGuid"
  template-guid="ctrl.$state.params.templateGuid"
  is-visit-detail-selected="ctrl.isVisitDetailSelected"
/>
