<div class="modal-header">
    <h3 class="modal-title">Error</h3>
</div>
<div class="modal-body" >
    {{respStatus}}
</div>
<div class="modal-footer">
    <button class="btn btn-primary" ng-click="retry()">Retry</button>
    <button class="btn btn-warning" ng-click="goOffline()" ng-hide="hideOffline">Go offline</button>
</div>

