<div class="input-group" ng-class="{'datepicker-icon-only' : iconOnly}">
    <span ng-if="showArrows" class="input-group-addon daterangepicker-arrows" ng-click="prev()"
          ng-class="{'daterangepicker-arrows-disabled': disabled || prevDisabled}" data-toggle="tooltip" title="Previous">
        <i class="fa fa-caret-left"></i>
    </span>

    <span ng-if="!showArrows && !iconOnly" class="input-group-addon">
        <i class="fa fa-calendar"></i>
        <span visually-hidden>{{ 'DATE_RANGE_PICKER_CUSTOMRANGE' | translate }}</span>
    </span>

    <form unsaved-warning-form>
        <button
            ng-if="iconOnly"
            class="input-group-addon datepicker-icon-only__icon"
            ng-click="toggleDatePicker()">
            <i class="fa fa-calendar"></i>
            <span visually-hidden>{{ 'DATE_RANGE_PICKER_CUSTOMRANGE' | translate }}</span>
        </button>

        <input
            type="text"
            id="{{id}}"
            name="dateselection-picker"
            ng-disabled="disabled"
            placeholder="{{DATE_RANGE_PICKER_DATE_PLACEHOLDER}}"
            ng-class="{'datepicker-icon-only__input-helper': iconOnly}"
            class="form-control no-ms-clear-icon">

        <span ng-show="allowClear" class="single-picker-input-input-remove" ng-click="clearInput()">
            <i ng-show="true" class="fa fa-times"></i>
        </span>
    </form>

    <span ng-if="showArrows" class="input-group-addon daterangepicker-arrows" ng-click="next()"
          ng-class="{'daterangepicker-arrows-disabled': disabled || nextDisabled}" data-toggle="tooltip" title="Next">
        <i class="fa fa-caret-right"></i>
    </span>
</div>
