<ifr-page-title pageTitle="'PROJECTTIME_RECORDING_PAGE_TITLE' | translate " />

<project-time-management
  activity-right="activityRight"
  selected-person="selectedPerson"
  set-selected-person="setSelectedPerson"
  preselect-date-from="routeSelectedDateFrom"
  preselect-date-to="routeSelectedDateTo"
  allow-project-time-changes-after-approval="allowProjectTimeChangesAfterApproval"
  withdraw-release-permission="resetReleasePermission"
  approval-permission="approvalPermission"
  withdraw-approval-permission="resetApprovalPermission"
  will-leave="vm.willLeave"
  on-will-leave-result="onWillLeaveResult"
/>

<iflx-scroll-up-directive></iflx-scroll-up-directive>
