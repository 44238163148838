<ifr-page-title pageTitle="'LEFT_PANEL_LAMPTABLE' | translate "/>
<div
  id="wid-plan-content-0"
>
  <status-panel
    api-url="'/WebPlanningAPI'"
    auth-token="authToken"
    locale="locale"
    theme="theme"
    on-auth-error="onAuthError"
    personnelNumberEnabled="personnelNumberEnabled"
  ></status-panel>
</div>
