<ifr-page-title pageTitle="'LEFT_PANEL_ORGANIZATIONAL_DATA' | translate " />
<person-records-and-org-units
  person-action="ctrl.$state.params.personAction"
  person-guid="ctrl.$state.params.personGuid"
  current-state-url="ctrl.$state.current.url"
  orgUnit-action="ctrl.$state.params.orgUnitAction"
  org-unit-guid="ctrl.$state.params.orgUnitGuid"
  will-leave="ctrl.willLeave"
  on-will-leave-result="onWillLeaveResult"
/>
