<div class="modal-header">
    <h3 class="modal-title">{{'SINGLE_PICKER' | translate}}</h3>
</div>
<div class="modal-body single-picker" iflx-stop-propagation="touchend">
    <div class="row">
        <div class="col-md-12">
            <form class="padding-bottom-10">
                <div class="input-group quick-search">
                            <span class=input-group-addon>
                                <i class="fa fa-fw fa-search text-muted"></i>
                            </span>
                    <input autofocus="autofocus" type="search"
                           iflx-auto-focus="setAutoFocus"
                           ng-model="searchQuery"
                           ng-model-options="{debounce: 500 }"
                           ng-change="onInputChange(searchQuery)"
                           placeholder="{{'SINGLE_PICKER_SEARCHCRITERIA' | translate}}" class="form-control">

                    <span class="single-picker-modal-spinner">
                        <i ng-show="isLoading" class="fa fa-refresh fa-spin"></i>
                    </span>

                    <span class="single-picker-modal-remove" ng-click="clearModalInput(searchQuery)">
                        <i ng-show="searchQuery.length > 0" class="fa fa-times"></i>
                    </span>
                </div>
            </form>

            <table class="single-picker-table row-border hover table table-bordered table-hover"
                   st-table="displayItems"
                   st-class-ascent="st-sort-ascent"
                   st-class-descent="st-sort-descent"
                   st-safe-src="items">
                <thead>
                <tr>
                    <th st-sort="displayName">
                        {{'PICKER_RESULTS' | translate }}
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr data-ng-repeat="item in displayItems" data-ng-click="select(item)">
                    <td>{{item.displayName}}</td>
                </tr>
                </tbody>
                <tfoot>
                <tr ng-if="vm.totalItems > 10">
                    <td class="text-center"
                        st-pagination=""
                        st-items-by-page="10"
                        st-template="app/components/picker/partials/pickerPagination.html"
                        colspan="2"></td>
                </tr>
                </tfoot>
            </table>
            <strong>{{'EMPLOYEE_SEARCH_NUMBERITEMS' | translate}} {{totalItems}}</strong> <span>({{'SEARCH_HINT_MAXCOUNT' | translatePlaceholder: {'maxCount': maxCount} }})</span>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-default" ng-click="close()">{{'BUTTON_CLOSE' | translate}}</button>
</div>