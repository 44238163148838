<ifr-page-title pageTitle="'DISPATCH_SCHEDULE_SETTINGS' | translate " />
<div id="wid-plan-content-0">
  <dispatch-schedule-settings
    type="6040"
    api-url="'/WebPlanningAPI'"
    auth-token="authToken"
    locale="locale"
    theme="theme"
    on-auth-error="onAuthError"
    will-leave="vm.willLeave"
    onWillLeaveResult="onWillLeaveResult"
  ></dispatch-schedule-settings>
</div>
