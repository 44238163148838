<ifr-page-title pageTitle="'LEFT_PANEL_RULE_VIOLATIONS' | translate "/>
<div
  id="wid-plan-content-0"
>
  <rule-violations
    type="6040"
    api-url="'/WebPlanningAPI'"
    auth-token="authToken"
    locale="locale"
    user-id="userId"
    theme="theme"
    licenses="licenses"
    on-auth-error="onAuthError"
    personnelNumberEnabled="personnelNumberEnabled"
    simple-user-id="simpleUserId"
    selectedPeople="selectedPeople"
    onSelectedPeopleChange="onSelectedPeopleChange"
    will-leave="vm.willLeave"
    onWillLeaveResult="onWillLeaveResult"
  ></rule-violations>
</div>
