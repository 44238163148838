<!--popover triangle-->
<div class="iflx-popover-triangle"></div>
<!--popover body-->
<div class="iflx-popover-tooltip">
    <span class="tt-day">{{tooltip.dayLabel}}</span>

    <span ng-repeat="absence in tooltip.absences" class="tt-event"
          ng-class="{'tt-prioritized': (((tooltip.cellEntriesLength - 1) == $index && tooltip.absences.length > 1) && !$last)}">
        <strong ng-style="{'background': absence.colors.backgroundColor, 'color': absence.colors.color}">
            {{::absence.reasonShortcut}}
        </strong>
        <span>{{::absence.reasonLabel}}</span>

        <span class="absence-tooltip-event-type" ng-if="(absence.displayType === 0)" ng-bind="'(' + ('ABSENCE_TIME_ABSENCE_POSITION_FULLDAY' | translate) + ')'"></span>
        <span class="absence-tooltip-event-type" ng-if="(absence.displayType === 1)" ng-bind="'(' + ('ABSENCE_TIME_ABSENCE_POSITION_FIRSTHALF' | translate) + ')'"></span>
        <span class="absence-tooltip-event-type" ng-if="(absence.displayType === 2)" ng-bind="'(' + ('ABSENCE_TIME_ABSENCE_POSITION_SECONDHALF' | translate) + ')'"></span>
        <span class="absence-tooltip-event-type" ng-if="(absence.displayType === 3)" ng-bind="'(' + absence.absencePercentage + '%)'"></span>
        <span class="absence-tooltip-event-type" ng-if="(absence.displayType === 5)" ng-bind="'(' + (absence.absenceDuration | durationInHours) + ')'"></span>

        <!--<em ng-show="absence.isApproved" ng-bind="'ABSENCE_TIME_APPROVED' | translate"></em>-->
        <em ng-show="!absence.approved" ng-bind="'ABSENCE_TIME_REQUESTEDINSERT' | translate"></em>
        <em ng-show="absence.status == 'approvedandrequestedforclear'" ng-bind="'ABSENCE_TIME_APPROVEDANDREQUESTEDFORCLEAR' | translate"></em>
    </span>
</div>
