<ifr-page-title pageTitle="'LEFT_PANEL_YEARLY_CALENDAR' | translate " />
<div
  id="wid-plan-content-0"
>
  <yearly-calendar
    type="6040"
    api-url="'/WebPlanningAPI'"
    auth-token="authToken"
    locale="locale"
    user-id="userId"
    theme="theme"
    licenses="licenses"
    on-auth-error="onAuthError"
    simple-user-id="simpleUserId"
    personnelNumberEnabled="personnelNumberEnabled"
    selectedPerson="selectedPerson"
    onSelectedPersonChange="onSelectedPersonChange"
    selectedBalanceSet="selectedBalanceSet"
    onSelectedBalanceSetChange="onSelectedBalanceSetChange"
    pickerContext="'planningYearlyCalendar'"
    fromDay="fromDay"
    toDay="toDay"
  ></yearly-calendar>
</div>
