<ifr-page-title pageTitle="'LEFT_PANEL_SICK_NOTES' | translate " />
<div id="wid-plan-content-0">
  <sick-notes
    type="6040" 
    api-url="'/WebPlanningAPI'" 
    auth-token="authToken" 
    locale="locale" 
    theme="theme"
    on-auth-error="onAuthError"
    personnelNumberEnabled="personnelNumberEnabled"
    notificationDisplayTimeMap="notificationDisplayTimeMap"
    isAutomaticLoadingEnabled="isAutomaticLoadingEnabled"></sick-notes>
</div>
