<div class="dropdown">

    <input ng-model="selectedItem.label"
           type="text"
           data-toggle="dropdown"
           class="form-control iflx-dropdown no-ms-clear-icon {{inputCss}}"
           placeholder="{{ 'COMBOBOX_CHOOSE' | translate }} "
           ng-click="setCSS()">

    <span class="combobox-input-remove" ng-click="deleteSelectedItem()">
        <i ng-show="selectedItem !== undefined && selectedItem.label !== ''" class="fa fa-times"></i>
    </span>

    <ul class="dropdown-menu iflx-dropdown-menu" role="menu"
        aria-labelledby="dropdownSourceAccount">
        <li role="presentation"
            ng-repeat="item in items | filter: selectedItem | orderBy : 'label' | filter: customFilterFunc()">
            <a role="menuitem" tabindex="-1"
               ng-click="selectTheItem( item )"
               class="iflx-dropdown-li">
                <nobr> {{ item.label }}</nobr>
            </a>
        </li>
    </ul>

</div>