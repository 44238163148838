<ifr-page-title pageTitle="'LEFT_PANEL_REQUESTS_LIST' | translate "/>

      <requests-list
        activity-right="vm.activityRight"
        theme="vm.selectedDesign"
        api-url="'/WebPlanningAPI'"
        auth-token="authToken"
        locale="locale"
        on-auth-error="onAuthError"
      />


