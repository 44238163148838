<ifr-page-title pageTitle="'LEFT_PANEL_TIME_CORRECTIONS' | translate "/>
<div
  id="wid-plan-content-0"
>
  <time-corrections
    api-url="'/WebPlanningAPI'"
    auth-token="authToken"
    locale="locale"
    user-id="userId"
    theme="theme"
    on-auth-error="onAuthError"
    fromDay="fromDay"
    toDay="toDay"
    personnelNumberEnabled="personnelNumberEnabled"
    simple-user-id="simpleUserId"
    selectedPerson="selectedPerson"
    onSelectedPersonChange="onSelectedPersonChange"
    selectedBalanceSet="selectedBalanceSet"
    onSelectedBalanceSetChange="onSelectedBalanceSetChange"
    will-leave="vm.willLeave"
    onWillLeaveResult="onWillLeaveResult"
  ></time-corrections>
</div>
