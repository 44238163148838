<!-- Header with type selection buttons -->
<div class="modal-header">
    <div class="btn-group picker-type-selection pull-right" data-toggle="buttons">
        <label ng-repeat="picker in vm.pickerCollection.pickers"
               ng-if="vm.isMultiPicker || (vm.isMultiSelection && vm.hasVisibleFavorites())"
               class="btn btn-default btn-xs"
               ng-class="{'active': vm.selectedPicker.pickerType == picker.pickerType && !vm.showFavorites}"
               ng-click="vm.selectPickerType(picker)">
            <input type="radio" name="picker-switch">
            <i class="{{picker.quickSearchIcon}}"></i>
            <strong>{{picker.pickerName | translate}}</strong>
        </label>
        <label class="btn btn-default btn-xs picker-favorite-lbl" ng-if="vm.isMultiSelection && vm.hasFavorites()"
               ng-click="vm.showFavoriteList()">
            <input type="radio" name="picker-switch">
            <i class="fa fa-star-o"></i>
            <strong>{{'PICKER_FAVORITES' | translate}}</strong>
        </label>
    </div>
    <h3 class="modal-title">{{'EMPLOYEE_SEARCH_HEADER' | translate}}</h3>
</div>

<div class="modal-body person-search-modal">
    <div class="row">
        <div class="col-md-12">

            <!-- Input form for search -->
            <form class="padding-bottom-10" ng-show="!vm.showFavorites">
                <div class="input-group quick-search">
                            <span class=input-group-addon>
                                <i class="fa fa-fw fa-search text-muted"></i>
                            </span>
                    <input iflx-auto-focus="true" only-for-desktop="true"
                           type="search"
                           ng-model="modalQuery"
                           ng-model-options="{debounce: 500 }"
                           ng-change="vm.onModalInputChange(modalQuery)"
                           placeholder="{{vm.selectedPicker.quickSearchPlaceholder | translate}}" class="form-control">

                    <span class="person-search-input-modal-spinner">
                        <i ng-show="vm.isLoading" class="fa fa-refresh fa-spin"></i>
                    </span>

                    <span class="person-search-modal-input-remove" ng-click="vm.clearModalInput(searchQuery)">
                        <i ng-show="modalQuery.length > 0" class="fa fa-times"></i>
                    </span>
                </div>
            </form>

            <!-- Badge section -->
            <div class="picker-badge-section" ng-show="vm.isMultiSelection && !vm.showFavorites">

                <div class="pull-right">
                    <!-- Favorite button -->
                    <i ng-if="(vm.pickerCollection.anySelected && !vm.saveAsFavoriteMode && !vm.pickerCollection.selectionSaved)"
                       ng-class="{
                       'fa-star': vm.pickerCollection.selectionSaved,
                       'fa-star-o': !vm.pickerCollection.selectionSaved}"
                       ng-click="vm.enterFavoriteName()"
                       data-original-title="{{'PICKER_SAVE_GROUP' | translate }}"
                       data-toggle="tooltip" title="{{'PICKER_SAVE_GROUP' | translate }}"
                       class="btn btn-default btn-xs fa fa-star-o"
                    ></i>

                    <!-- Input field for favorite name -->
                    <span ng-show="vm.pickerCollection.anySelected && vm.saveAsFavoriteMode">
                        <input class="margin-right-5" type="text" ng-model="vm.saveAsFavoriteName"
                               ng-keypress="vm.onFavoriteKeyPress($event)"
                               id="picker-favorite-save-btn"
                               autofocus="autofocus"
                               async-focus="true"
                               placeholder="{{'PICKER_FAVORITE_NAME' | translate}}">

                        <!-- Favorite save button -->
                        <div class="btn btn-xs btn-success"
                             ng-click="vm.saveAsFavorite()">{{'BUTTON_SAVE' | translate}}
                        </div>
                    </span>

                    <!-- Dropdown-List for sorting the badges -->
                    <div class="dropdown" ng-show="badges.length > 1 && vm.sortingEnabled">
                        <button class="btn btn-default btn-xs dropdown-toggle" type="button" data-toggle="dropdown">
                            <i class="fa fa-list-ol"
                               aria-hidden="true"
                               data-original-title="{{'PICKER_SORT_SELECTION' | translate }}"
                               data-toggle="tooltip" title="{{'PICKER_SORT_SELECTION' | translate }}"></i>
                        </button>
                        <ul class="dropdown-menu dropdown-list" dragula='"badgesDropdown"' dragula-model="badges">
                            <li ng-repeat="badge in badges" class="dropdown-list-entry grabable">
                                <span>
                                    <i class="fa fa-bars" aria-hidden="true"></i>
                                    {{badge.displayName}}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- Favorite Badges -->
                <!-- no DragNDrop Sorting -->
                <ul ng-if="!vm.sortingEnabled">
                    <li ng-repeat="badge in badges"
                        class="badge {{badge.badgeClass}}">
                        {{badge.displayName}}
                        <i class="fa fa-times" ng-click="vm.badgeClick(badge)"></i>
                    </li>
                </ul>

                <!-- with DragNDrop Sorting -->
                <ul class="container" ng-if="vm.sortingEnabled" dragula='"badges"' dragula-model="badges">
                    <li ng-repeat="badge in badges"
                        class="badge {{badge.badgeClass}} grabbable"
                        data-original-title="{{'PICKER_SORT_BADGES' | translate }}"
                        data-toggle="tooltip" title="{{'PICKER_SORT_BADGES' | translate }}">
                            {{badge.displayName}}
                        <i class="fa fa-times" ng-click="vm.badgeClick(badge)"></i>
                    </li>
                </ul>

            </div>

            <!-- Favorites table -->
            <table ng-if="vm.showFavorites" class="row-border hover table table-bordered table-hover"
                   st-table="displayedFavoritesCollection"
                   st-safe-src="vm.favorites.collections"
                   st-table-reset-pagination-state-listener>
                <thead>
                <tr>
                    <th>{{'PICKER_FAVORITES' | translate }}</th>
                    <th class="remove-button-column">{{'TABLE_ACTIONS' | translate }}</th>
                </tr>
                </thead>
                <tbody>
                <tr ng-repeat="favorite in displayedFavoritesCollection">
                    <td ng-click="vm.loadFavorite(favorite)" class="clickable-favorite-cell">
                        <div class="picker-badge-section"><b>{{favorite.name}}</b></div>
                        <div class="picker-badge-section">
                            <span class="badge {{item.badgeClass}}" ng-repeat="item in favorite.collection.getItems()">
                                {{item.displayName}}
                            </span>
                        </div>
                    </td>
                    <td>
                        <div class="btn btn-xs btn-danger red"
                             ng-click="vm.removeFavorite(favorite)">
                            <span class="fa fa-trash-o"></span>
                        </div>
                    </td>
                </tr>
                </tbody>
              <tfoot>
              <tr ng-if="vm.favorites.collections.length > 5">
                <td class="text-center"
                    st-pagination=""
                    data-st-items-by-page="5"
                    data-st-template="app/components/picker/partials/pickerPagination.html"
                    colspan="2"></td>
              </tr>
              </tfoot>
            </table>

            <!-- Result table -->
            <table ng-if="!vm.showFavorites" class="row-border hover table table-bordered table-hover"
                   st-table="vm.stRows"
                   st-safe-src="vm.entries"
                   st-table-reset-pagination-state-listener>
                <thead>
                <tr>
                    <th ng-repeat="column in vm.selectedPicker.columns" st-sort="{{column.columnId}}">
                        <i ng-show="column.columnIcon" class="{{column.columnIcon}}"></i>
                        {{column.columnName | translate}}
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr data-ng-repeat="row in vm.stRows"
                    data-ng-click="vm.onSelectPickerRow(row)"
                    data-ng-class="{'row-focus': row.selected} ">
                    <td ng-repeat="column in vm.selectedPicker.columns">
                        {{row[column.columnId]}}
                    </td>
                </tr>
                <tr ng-if="vm.stRows.length === 0">
                    <td align="center" colspan="{{vm.selectedPicker.columns.length}}">{{'LANG_NODATA' | translate}}</td>
                </tr>
                </tbody>

                <tfoot>
                <tr ng-if="vm.totalItems > 10">
                    <td class="text-center"
                        st-pagination=""
                        st-items-by-page="10"
                        st-template="app/components/picker/partials/pickerPagination.html"
                        colspan="{{vm.selectedPicker.columns.length}}"></td>
                </tr>
                </tfoot>
            </table>
            <div ng-show="!vm.showFavorites">
                <strong>
                    {{'EMPLOYEE_SEARCH_NUMBERITEMS' | translate}} {{vm.totalItems}}
                </strong>
                <span>
                    ({{'SEARCH_HINT_MAXCOUNT' | translatePlaceholder: {'maxCount':vm.maxCount} }})
                </span>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer picker-modal-footer">
    <div class="select-btns-row" ng-if="vm.isMultiSelection && !vm.showFavorites">
        <div class="btn btn-default btn-select-all" ng-click="vm.selectAll()">
        {{'LANG_OFFLINEBOOKING_SELECTALL' | translate}}
        </div>
        <div class="btn btn-default btn-deselect-all" ng-click="vm.deselectAll()">
        {{'LANG_OFFLINEBOOKING_DESELECTALL' | translate}}
        </div>
    </div>

    <div class="btn btn-default" ng-click="vm.dismiss()">{{'BUTTON_CLOSE' | translate}}</div>
    <div class="btn btn-success" ng-click="vm.close()" ng-show="!vm.showFavorites && vm.isMultiSelection">{{'BUTTON_APPLY' | translate}}
    </div>
</div>
