<div
  class="jarviswidget jarviswidget-color-darken my-information"
  id="wid-plan-content-0"
>
  <ad-hoc-reporting
    api-url="'/WebPlanningAPI'"
    auth-token="authToken"
    locale="locale"
    theme="theme"
    on-auth-error="onAuthError"
    on-can-leave-change="onCanLeaveChange"
  ></ad-hoc-reporting>
</div>


