<ifr-page-title pageTitle="'COMBI_ENTRIES' | translate " />
<div id="wid-plan-content-0">
  <combi-entries
    api-url="'/WebPlanningAPI'"
    auth-token="authToken"
    locale="locale"
    theme="theme"
    on-auth-error="onAuthError"
    onCanLeaveChange="onCanLeaveChange"
    selectedShiftSet="selectedShiftSet"
    onSelectedShiftSetChange="onSelectedShiftSetChange"
    onSelectedSequenceChange="onSelectedSequenceChange"
    selectedSequenceId="selectedSequenceId"
    will-leave="vm.willLeave"
    onWillLeaveResult="onWillLeaveResult"
  ></combi-entries>
</div>
