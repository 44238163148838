<div class="input-group picker">

    <!-- Left icon with optional dropdown to choose picker type -->
    <div class="input-group-addon picker-left-icon"
         ng-click="togglePickerSelection($event)"
         ng-class="{'open': iSelectionOpened,
         'disabled': vm.pickerCollection.multipleSelected || !vm.isMultiPicker || pickerDisabled}"
         data-toggle="tooltip"
         title="{{'EMPLOYEE_SEARCH_HEADER' | translate}}">
        <i class="dropdown-toggle {{vm.pickerCollection.selectedPicker.quickSearchIcon}} fa-fw"
           aria-haspopup="true"
           aria-expanded="false"></i>
        <i class="fa fa-caret-down"></i>

        <ul ng-if="!vm.pickerCollection.multipleSelected && vm.isMultiPicker && !vm.isSimpleUser"
            class="dropdown-menu">
            <li ng-repeat="picker in vm.pickerCollection.pickers">
                <a href="" ng-click="setCurrentPickerType(picker)">{{picker.pickerName | translate }}</a>
            </li>
        </ul>
    </div>

    <!-- Input field for quicksearch -->
    <input type="text"
           autocomplete="off"
           ng-if="vm.pickerCollection.selectedPicker"
           data-max-results="{{vm.pickerCollection.selectedPicker.quickSearchMaxResults}}"
           ng-readonly="vm.multipleSelected || pickerDisabled"
           ng-model="vm.quickSearchInput"
           data-typeahead-min-length="vm.pickerCollection.selectedPicker.quickSearchMinLength || 2"
           data-typeahead-wait-ms="400"
           data-typeahead-editable="true"
           data-typeahead-select-on-blur="false"
           data-typeahead-on-select="vm.onQuickSearchSelect($item, $model, $label)"
           data-typeahead-loading="isLoading"
           data-typeahead-template-url="{{vm.pickerCollection.selectedPicker.quickSearchContentTemplate}}"
           data-typeahead-append-to-body="false"
           placeholder="{{vm.pickerCollection.selectedPicker.quickSearchPlaceholder | translate}}"
           data-uib-typeahead="item.displayName for item in vm.quickSearch($viewValue)"
           class="form-control person-search-input"
           ng-class="{'hide-placeholder': vm.quickSearchInput, 'disabled': pickerDisabled}"
           ng-required="required"
           ng-blur="vm.setLastUserOrKeepQuicksearch()"
           id="{{inputId}}">
    <span class="person-search-input-spinner">
        <i ng-show="isLoading" class="fa fa-refresh fa-spin"></i>
    </span>

    <!-- Clear input icon (x) if some element is selected -->
    <span ng-hide="vm.isSimpleUser" class="person-search-input-remove" ng-click="vm.clearInput()">
        <i ng-show="vm.pickerCollection.selectedElements.count() > 0" class="fa fa-times"></i>
    </span>

    <!-- Open modal window with full search functionality -->
    <a class="input-group-addon ng-scope person-search-input-users btn"
       ng-click="vm.openModal()"
       ng-class="{'disabled': pickerDisabled}"
       data-toggle="tooltip"
       title="{{'EMPLOYEE_SEARCH_HEADER' | translate}}">
        <i class="fa fa-ellipsis-h"></i>
    </a>
</div>
