<!-- HEADER -->
<page-header
  is-login-page="false"
  current-state-name="ctrl.getCurrentStateName()"
  home-page-permission="ctrl.homePageScreenId"
  home-page-url="ctrl.homePageUrl"
  menu-state-change-callback="ctrl.menuStateChangeListener"
/>
<!-- END HEADER -->

<div class="flex">
  <!-- NAVIGATION -->
  <div data-smart-include="app/layout/partials/navigation.tpl.html" class="placeholder-left-panel"></div>
  <!-- END NAVIGATION -->

  <!-- MAIN PANEL -->
  <main id="main" class="flex-shrink flex-grow">
    <div class="smart-router-animation-wrap" data-smart-router-animation-wrap="content content@app" data-wrap-for="#content">
        <div id="content" data-ui-view="content" data-autoscroll="false"></div>
    </div>
  </main>
  <!-- END MAIN PANEL -->
</div>

<!-- PAGE FOOTER -->
<!--<div data-smart-include="app/layout/partials/footer.tpl.html"></div>
<div data-smart-include="app/layout/shortcut/shortcut.tpl.html"></div>-->

<!-- END PAGE FOOTER -->


