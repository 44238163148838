<div>
    <iflx-date-range-picker
      show-arrows="true"
      single-date-picker="false"
      start-date="startDate"
      end-date="endDate"
      show-week-numbers="true"
      ranges="ranges"
      parent-element="{{parentElement}}"
      on-selection="onSelectionWrapper(start, end)"
    ></iflx-date-range-picker>
</div>
