<div class="input-group single-picker-input-resultlist" ng-if="!noInput">
    <div class=input-group-addon>
        <i class="fa fa-search"></i>
      <span visually-hidden>{{'ACCESSIBILITY_PICKER_SEARCH' | translate }}</span>
    </div>

  <input type="text"
         iflx-auto-focus="setAutoFocus"
         ng-model="inputForm.selectedEntry"
         ng-change="onInputChange()"
         ng-required="isRequired"

         typeahead-min-length="minChars || 2"
         typeahead-wait-ms="400"
         typeahead-editable="true"
         typeahead-on-select="onSelect($item, $model, $label)"
         typeahead-loading="isLoading"
         typeahead-template-url="app/_common/partials/singlePickerContent.html"
         typeahead-append-to-body="false"
         uib-typeahead="item.displayName for item in loadData($viewValue)"
         placeholder="{{'SINGLE_PICKER_SEARCHCRITERIA' | translate}}"
         class="form-control single-picker-input person-search-input"/>
  <span class="single-picker-input-spinner" aria-hidden="true">
        <i ng-show="isLoading" class="fa fa-refresh fa-spin"></i>
    </span>

  <button class="single-picker-input-input-remove" ng-click="clearInput()">
    <i ng-show="selectedEntry !== null" class="fa fa-times"></i>
    <span visually-hidden>{{'ACCESSIBILITY_PICKER_SEARCH_REMOVE' | translate }}</span>
  </button>

  <button class="input-group-addon ng-scope single-picker-input-entries" ng-click="onOpenSinglePicker()"
          data-toggle="tooltip" title="{{'SINGLE_PICKER' | translate}}">
    <i class="fa fa-list" aria-hidden="true"></i>
    <span visually-hidden>{{'SINGLE_PICKER' | translate }}</span>
  </button>

</div>
<div ng-if="noInput && !disabled" ng-click="onOpenSinglePicker()">
  <table class="single-picker-icon-table">
    <tbody>
    <tr>
      <td>
        <span ng-model="selectedEntry" ng-required="isRequired">{{selectedEntry.displayName}}</span>
      </td>
      <td>
        <i class="fa fa-search pull-right" data-toggle="tooltip" title="{{'SINGLE_PICKER' | translate}}"></i>
        <span visually-hidden>{{'ACCESSIBILITY_PICKER_SEARCH' | translate }}</span>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<div ng-if="disabled">
  <span ng-model="selectedEntry" ng-required="isRequired">{{selectedEntry.displayName}}</span>
</div>
