<div id="shortcut">
    <ul>
        <li>
            <a href="#/inbox/" class="jarvismetro-tile big-cubes bg-color-blue"> <span class="iconbox"> <i
                    class="fa fa-envelope fa-4x"></i> <span>Mail <span
                    class="label pull-right bg-color-darken">14</span></span> </span> </a>
        </li>
        <li>
            <a href="#/calendar" class="jarvismetro-tile big-cubes bg-color-orangeDark"> <span class="iconbox"> <i
                    class="fa fa-calendar fa-4x"></i> <span>Calendar</span> </span> </a>
        </li>
        <li>
            <a href="#/maps" class="jarvismetro-tile big-cubes bg-color-purple"> <span class="iconbox"> <i
                    class="fa fa-map-marker fa-4x"></i> <span>Maps</span> </span> </a>
        </li>
        <li>
            <a href="#/invoice" class="jarvismetro-tile big-cubes bg-color-blueDark"> <span class="iconbox"> <i
                    class="fa fa-book fa-4x"></i> <span>Invoice <span class="label pull-right bg-color-darken">99</span></span> </span>
            </a>
        </li>
        <li>
            <a href="#/gallery" class="jarvismetro-tile big-cubes bg-color-greenLight"> <span class="iconbox"> <i
                    class="fa fa-picture-o fa-4x"></i> <span>Gallery </span> </span> </a>
        </li>
        <li>
            <a href="#/profile" class="jarvismetro-tile big-cubes selected bg-color-pinkDark"> <span class="iconbox"> <i
                    class="fa fa-user fa-4x"></i> <span>My Profile </span> </span> </a>
        </li>
    </ul>
</div>