<div>
  <navigation-menu-react
    open-screen="ctrl.openScreen"
    current-state-name="ctrl.getCurrentStateName()"
    get-has-unsaved-changes="ctrl.getHasUnsavedChanges"
    menu-global-settings="windowGlobals"
    user-display-name="ctrl.getUserDisplayName()"
    class="navigation-menu"
  />
  <status-panel-employees-watch
    api-url="'/WebPlanningAPI'"
    auth-token="ctrl.getAuthToken()"
    locale="ctrl.getLocaleFullCode()"
  />
</div>
