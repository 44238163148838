<a href="javascript:void(0);"
   class="context-help-container">
    <div class="context-help"
         template="{{template}}"
         iflx-popover
         plain="true"
         trigger="click"
         hide-others-on-open="true">
        <i class="fa fa-question"></i>
        <span visually-hidden>{{ 'ACCESSIBILITY_DISPLAY_HELP' | translate }}</span>
    </div>
</a>
