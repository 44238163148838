<ifr-page-title pageTitle="'LEFT_PANEL_REQUESTS_LIST' | translate "/>
<div
  class="jarviswidget jarviswidget-color-darken my-information"
  id="wid-plan-content-0"
>
  <react-request-list
    api-url="'/WebPlanningAPI'"
    auth-token="authToken"
    locale="locale"
    theme="theme"
    on-auth-error="onAuthError"
    personnelNumberEnabled="personnelNumberEnabled"
  ></react-request-list>
</div>
