<ifr-page-title pageTitle="'DISPATCH_SCHEDULE' | translate " />
<div id="wid-plan-content-0">
  <dispatch-schedule
    api-url="'/WebPlanningAPI'"
    auth-token="authToken"
    selected-plan="selectedPlan"
    locale="locale"
    theme="theme"
    on-auth-error="onAuthError"
    personnelNumberEnabled="personnelNumberEnabled"
    selectedSchedulingUnit="selectedSchedulingUnit"
    onSelectedSchedulingUnitChange="onSelectedSchedulingUnitChange"
    selectedBalanceSet="selectedBalanceSet"
    onSelectedBalanceSetChange="onSelectedBalanceSetChange"
  ></dispatch-schedule>
</div>
